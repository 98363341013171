import { useRouter } from "next/router";
import { useContext } from "react";

import { ConfigVariablesContext } from "../contexts/ConfigVariables";
import { UserContext } from "../contexts/UserContext";

export function useAnalytics() {
  const router = useRouter();
  const user = useContext(UserContext);
  const { Mixpanel } = useContext(ConfigVariablesContext);

  const page = router.pathname.includes("/instances")
    ? "instances"
    : router.pathname.includes("/dashboard")
    ? "dashboard"
    : router.pathname.includes("/[scan_id]")
    ? "issues"
    : router.pathname.includes("/teams")
    ? "teams"
    : router.pathname.includes("/settings")
    ? "settings"
    : router.pathname.includes("/update-set/")
    ? "update-set"
    : router.pathname.includes("/record/")
    ? "record"
    : router.pathname.includes("/application/")
    ? "application"
    : "";

  function track(
    event: string,
    metadata: Record<string, string | number> = {},
  ) {
    // console.log(event, metadata);

    if (Mixpanel) {
      Mixpanel.track(event, {
        ...metadata,
        $USER_NAME: user.name,
        $USER_ID: user.id,
      });
      return;
    }

    if (process.env.NODE_ENV === "production") {
      console.warn("Mixpanel was not loaded properly");
    }
  }

  return { track, page, trackUrl: router.asPath };
}
